<template>
  <div
    v-if="show"
    class="fixed inset-0 overflow-y-auto s-modal-opened-bg bg-opacity-40 transition-opacity h-screen flex justify-center md:items-center"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    @click="close"
  >
    <div
      id="modal-title"
      class="md:min-h-screen flex justify-center items-center"
    >
      <div>
        <div
          :class="['relative md:top-auto w-full', hasShadow ? 'shadow-lg' : '', classes]"
          @click.stop
        >
          <div
            v-if="hasCloseBtn"
            class="modal-close absolute s-modal-close"
            @click="close"
          >
            <IconCloseModal class="text-black cursor-pointer" />
          </div>
          <slot name="modal-content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    props: {
      show: {
        type: Boolean,
        default: false
      },
      classes: {
        type: String,
        default: ''
      },
      hasShadow: {
        type: Boolean,
        default: true
      },
      hasCloseBtn: {
        type: Boolean,
        default: true
      }
    },
    emits: ['close'],
    watch: {
      show() {
        if (this.show) {
          this.xCloseMobileNavbar()
        }
      }
    },

    methods: {
      ...mapActions(['xCloseMobileNavbar']),

      close() {
        this.$emit('close')
      }
    }
  }
</script>

<style lang="postcss" scoped>
  .s-modal-opened-bg {
    background: linear-gradient(315deg, rgba(255, 252, 210, 0.9) 0%, rgba(229, 245, 247, 0.9) 49.94%);
    mix-blend-mode: normal;
    @apply z-[9999999];
  }

  .s-modal-close {
    right: -40px;
    top: -35px;
  }
</style>
